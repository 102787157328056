import Image from 'next/legacy/image'

import {trackSponsorClick} from '../analytics/tracking/trackSponsorClick'

export default function Companies({field}: {field: string}) {
  const companies = [
    {
      name: 'ZEISS',
      url: 'https://jobs.eyesoneyecare.com/companies/carl-zeiss-meditec/',
      logo: '/logos/companies/zeiss.png',
    },
    {
      name: 'National Vision',
      url: 'https://jobs.eyesoneyecare.com/companies/national-vision/',
      logo: '/logos/companies/nvi.png',
    },
    {
      name: 'EssilorLuxottica',
      url: 'https://jobs.eyesoneyecare.com/companies/luxottica/',
      logo: '/logos/companies/essilorluxottica-brand-logo.png',
    },
    {
      name: 'Essilor',
      url: 'https://jobs.eyesoneyecare.com/companies/essilor/',
      logo: '/logos/companies/essilor.png',
    },
    {
      name: 'Johnson & Johnson Vision',
      url: 'https://jobs.eyesoneyecare.com/companies/johnson-johnson-vision/',
      logo: '/logos/companies/jjv.png',
    },
    {
      name: 'ZEISS Professional Education',
      url: 'https://jobs.eyesoneyecare.com/companies/carl-zeiss-meditec-professional-education/',
      logo: '/logos/companies/zeiss-prof-ed.png',
    },
  ]
  return (
    <section id="eoCompanies">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 text-center">
            <h4 className="mb-4">Our industry partners</h4>
          </div>
          <div className="col-12 col-lg-10 row d-flex align-items-center">
            {companies.map((company, i) => (
              <div
                className="col-4 col-lg-2 my-1 my-lg-0 hover-slide-up d-flex justify-content-center"
                key={i}
              >
                <a
                  href={company.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={trackSponsorClick({
                    sponsor: {
                      name: company.name,
                      website: company.url,
                    },
                    source: `${field ? field + ' ' : ''}Home Page "Our industry partners"`,
                  })}
                >
                  <Image
                    alt={company.name}
                    src={company.logo}
                    className="card rounded border p-md-3 shadow-sm h-100"
                    width={100}
                    height={100}
                    role="button"
                    layout="intrinsic"
                  />
                </a>
              </div>
            ))}
          </div>
          <div className="col-12 text-center mt-4">
            <a href="/companies/" className="btn btn-outline-primary">
              Browse more companies
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
