import {z} from 'zod'

import {GetFeaturedJobsDataSchema} from '../../core/api'
import {slugify} from '../../core/utils'
import BannerAd from '../ads/BannerAd'
import StickyFooterAd from '../ads/StickyFooterAd'
import CardRow from '../cards/CardRow'
import Metadata from '../head/Metadata'
import {ParselyMetadata} from '../head/ParselyMetadata'
import EOSubnav from '../nav/EOSubnav'
import Companies from './Companies'
import FeaturedJobs from './FeaturedJobs'
import FeaturedWriters from './FeaturedWriters'
import Hero from './Hero'
import JobsBanner from './JobsBanner'
import SponsorLogos from './SponsorLogos'
import Sponsored from './Sponsored'
import Spotlight from './Spotlight'
import TopicList from './TopicList'
import {GetIndexPageQueryDataSchema, GetUniqueAuthorsDataSchema} from './types'

export const IndexPagePropsSchema = z.object({
  featuredAuthors: GetUniqueAuthorsDataSchema,
  featuredJobs: GetFeaturedJobsDataSchema,
  field: z.string(),
  indexPage: GetIndexPageQueryDataSchema.shape.indexPage,
  resources: GetIndexPageQueryDataSchema.shape.resources,
  headlines: z.object({
    clinical: z.string(),
    clinicalTopicList: z.string(),
    careers: z.string(),
    careersTopicList: z.string(),
    sponsored: z.string(),
  }),
  displayFeaturedWriters: z.boolean().optional(),
  displayCompanies: z.boolean().optional(),
  displayJobsCTA: z.boolean().optional(),
  metaImg: z.string().optional(),
})

export default function IndexPage({
  featuredAuthors,
  featuredJobs,
  field,
  indexPage,
  resources,
  headlines = {
    clinical: 'Clinical',
    clinicalTopicList: 'Clinical Specialties',
    careers: 'Careers',
    careersTopicList: 'Careers',
    sponsored: 'Sponsored Content',
  },
  displayFeaturedWriters = true,
  displayCompanies = true,
  displayJobsCTA = true,
  metaImg,
}: z.infer<typeof IndexPagePropsSchema>) {
  const {
    heroResource,
    hero,
    customSponsors,
    customAdCode,
    showSpotlight,
    spotlightSponsorText,
    spotlightResource,
    spotlight,
    navLinks,
    navResources,
    navAlert,
    topics,
    clinicalTopics,
    careerTopics,
    displayFeaturedJobs,
  } = indexPage

  const {
    recentResources,
    clinicalResources,
    careerResources,
    sponsoredResources,
  } = resources

  const url = `https://eyesoneyecare.com/${field ? `${slugify(field)}/` : ''}`
  const title = field ? `${field} | Eyes On Eyecare` : 'Eyes On Eyecare'

  return (
    <>
      <Metadata
        imgSrc={metaImg}
        title={title}
        url={url}
        description="The #1 resource for the next generation of optometrists and ophthalmologists. Explore in-depth articles, clinical guides, career resources, and more."
      />
      <ParselyMetadata url={url} title={title} />
      <EOSubnav
        field={field}
        navLinks={navLinks}
        navResources={navResources}
        navAlert={navAlert}
      />
      <Hero
        heroResource={heroResource || hero}
        resources={recentResources.slice(0, 3)}
        field={field}
      />
      {customSponsors && <SponsorLogos field={field} {...customSponsors} />}

      <BannerAd adCode={customAdCode ?? 'eoe_homepage_hero'} margin="my-3" />

      <FeaturedJobs
        displayFeaturedJobs={displayFeaturedJobs}
        jobs={featuredJobs}
      />

      <CardRow
        resources={recentResources.slice(3, 6)}
        headline="Recently Published"
      />
      <TopicList
        topics={topics}
        resources={recentResources.slice(6, 9)}
        field={field}
      />

      <Spotlight
        showSpotlight={showSpotlight ?? false}
        spotlightSponsorText={spotlightSponsorText}
        resource={spotlightResource || spotlight}
      />

      <CardRow
        resources={clinicalResources.slice(0, 3)}
        headline={headlines.clinical}
      />
      <TopicList
        topics={clinicalTopics}
        resources={clinicalResources.slice(3, 6)}
        field={field}
        headline={headlines.clinicalTopicList}
      />

      <CardRow
        resources={careerResources.slice(0, 3)}
        headline={headlines.careers}
      />
      <TopicList
        topics={careerTopics}
        resources={careerResources.slice(3, 6)}
        field={field}
        headline={headlines.careersTopicList}
      />

      {displayFeaturedWriters && <FeaturedWriters authors={featuredAuthors} />}

      <Sponsored
        headline={headlines.sponsored}
        resources={sponsoredResources.slice(0, 5)}
      />

      {displayCompanies && <Companies field={field} />}
      {displayJobsCTA && <JobsBanner />}

      <StickyFooterAd adCode="eoe_index_pages_sticky_footer" />
    </>
  )
}
